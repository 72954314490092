<template>
    <div class="index">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
            <router-view/>
        </div>

        <div class="index_body" v-show="!isQuiz">
            <div>
                <p class="index_body_info title" style="text-indent: 0;font-weight: bold; margin-bottom: 10px;">亲爱的各位会员：</p>

                <p class="index_body_info">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“从前车马很慢，书信很远，一生只够爱一个人。”在如今这个快节奏时代里，你会不会也怀念过去某段悠闲而专注的时光？适逢世界读书日，<span class="purple">4月20日（周六）</span>，<b>SAP北分工会将举办世界读书日特别活动——自制版画：春天的第一张藏书票</b>。邀您前来，花上半天时间，回看时光长廊，领略一凿一刻下独特的艺术气息，享受专注之后自制成品带来的满满成就感！
                </p>

                <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
                       style='border-collapse:collapse;border:none;width:90%;margin: 0 auto'>
                    <tr>
                        <td style='border:dashed #C00000 3.0pt'>
                            <ul style="list-style: square;padding-right: 15px">
                                <li style="margin-bottom: 20px">
                                    现场将提供相应工具及配套指导人员，在指导人员指引下完成版画绘制，活动将提供2款版画藏书票样式，
                                    <b><span style='font-family:"微软雅黑",sans-serif;color:black'>请选择其中一个款式：</span></b>
                                    <ul class="package_ul">
                                        <input class="mirror_item" type="radio" style="display: none" id="cupSpring" value="祥龙" v-model="staff.giftType">
                                        <label class="package_ul_item cup_spring" for="cupSpring"><img src="../assets/image/paint_1.jpg" alt="">
                                            <span>款式一：祥龙</span></label>

                                        <input class="coffee_item1" type="radio" style="display: none" id="cupFire" value="爱书" v-model="staff.giftType">
                                        <label class="package_ul_item cup_fire" for="cupFire"><img src="../assets/image/paint_2.jpg" alt="">
                                            <span>款式二：爱书</span></label>
                                    </ul>
                                </li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动时间：</span></b><b><span
                                    lang=EN-US style='font-family:"微软雅黑",sans-serif;color:#C00000'>4</span></b><b><span
                                    style='font-family:"微软雅黑",sans-serif;color:#C00000'>月<span lang=EN-US>20</span>日（周六）
    <span lang=EN-US>13:30-17:00</span></span></b></li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动地址：</span></b><span
                                    style='font-family:"微软雅黑",sans-serif; color:black'>公司10.15会议室</span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>

            </div>

            <div class="index_body_button_box">
                <button class="index_button online" @click="regist()" :disabled="isDisabled">{{ btnText }}</button>
            </div>

        </div>


        <div v-if="questionLoaded">
            <div v-show="isQuiz">
                <Quiz></Quiz>
            </div>
        </div>
        <!--      <Error v-if="isShow" @close="isShow=false" :message="message"></Error>-->
    </div>
</template>

<script>
import Quiz from "../components/quiz"
import {mapActions, mapState} from "vuex";
import axios from "axios";

export default {
    name: "login",
    components: {
        // Quiz,
    },
    data() {
        return {
            isQuiz: false,
            questionLoaded: false,
            isClick: true,
            stats: {
                status: false,
                submitted: 0,
                submitted_limit: 0,
                waiting: 0,
                waiting_limit: 0
            },
        }
    },
    computed: {
        ...mapState({
            staff: state => state.staff
        }),
        isDisabled: function () {
            return this.staff.giftType==='' || !(this.stats.status === false && (this.stats.submitted<this.stats.submitted_limit || this.stats.waiting<this.stats.waiting_limit));
        },
        btnText: function () {
            if (this.stats.status) {
                return '已经报名'
            }
            else if(!this.giftType){
                return '请选择款式'
            }
            else if(this.stats.submitted<this.stats.submitted_limit){
                return '我要报名'
            } else if (this.stats.waiting < this.stats.waiting_limit) {
                return '报名已满，登记候补'
            } else {
                return '本场已满员'
            }
        },
        giftType: function () {
            return this.staff.giftType;
        }
    },
    watch: {
        giftType() {
            this.getStats().then(result => {
                this.stats = result;
            })
        }
    },
    mounted() {
        // this.loadQuestion().then(() => {
        //     this.questionLoaded = true;
        // })
        this.getStats(this.staff.giftType).then(result => {
            this.stats = result;
        })
    },
    methods: {
        ...mapActions({
            'loadQuestion': 'initData',
            'getStats': 'getSubmitStats'
        }),
        regist() {
            // this.isQuiz = true

            console.log(this.isClick);
            if (this.isClick) {
                this.isClick = false
                axios.post('/submit', {score: 0, result: {}, staff: this.staff}).then(rsp => {
                    this.staff.msg = rsp.data.message;
                    this.$router.replace({name: 'result'})
                }).catch((e) => {
                    alert(e.response.data.message)
                    // this.isError = true
                    // this.message = e.response.data.message
                    this.isClick = true
                })
            }
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.index
    width 100%
    position relative

    .index_inner
        width 750px
        height 100%

        .index_banner
            width 100%

            .img
                width 100%;
                box-shadow 10px 10px 15px rgba(0, 0, 0, 0.2);

    .index_body
        width 750px
        //height 80vh
        position absolute
        top 300px
        display flex
        flex-direction column
        align-items center

        .purple
            color: $theme !important
            font-weight bold

        //justify-content space-around

        .index_body_info
            //text-indent 2em
            padding 0 55px
            margin 0 0 10px 0
            font-size 32px
            font-family "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
            font-weight 400
            color #000
            line-height 40px
            box-sizing border-box

            &.title
                font-size 36px

        .MsoTableGrid
            font-size 32px

            ul
                padding-left 1.5em

            li
                line-height 1.3em
                margin-bottom 5pt

        .index_body_img
            width 100%
            display flex
            flex-direction column
            align-items center

            img
                width 460px
                padding 10px 0

            span
                padding 10px 0

            .orspan
                font-size 40px
                font-weight bold
                //color red
                padding 20px 0

    .index_body_info_more
      padding-bottom 60px
    .index_body_button_box
      //width 100%
      padding 31px 75px
      .index_button
        buttonSet()



.package_ul
  list-style-type none
  padding 20px 0 0
  margin 0
  display flex
  flex-direction row
  flex-wrap wrap
  justify-content space-around
  .package_ul_item
    width: 40%;
    //height: 270px;
    background-size 100%
    background-repeat no-repeat
    img
      width 100%
      border-radius 25px
  label
    border 6px solid $bcGray
    border-radius 20px
  input:checked+label
    border 6px solid $theme
  span
    display block
    text-align center
    font-size 30px
</style>
